import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import transformFeatureItemData from '@activebrands/core-web/utils/transform-feature-item-data';
import FeaturePromotion from 'components/content-components/Promotional/FeaturePromotion';

const FeaturePromotionDataTransformerBlock = ({ content = {} }: any) => {
    const { anchorTag = [], heading = [], items = [], paragraph = [], tag = [] } = content;

    const headingSettings = transformHeading({ type: heading?.[0]?.type, defaultType: 'h3' });

    if (!items.length) {
        return null;
    }

    return (
        <FeaturePromotion
            columnSizes={[6, null, null, null, null, 3]}
            features={items.map(feature => transformFeatureItemData(feature))}
            heading={heading?.[0]?.text}
            headingType={headingSettings.type}
            id={anchorTag?.[0]?.anchorTag}
            imageSettings={{ width: [200, 300, 400, 500, 600], sizes: ['70vw', null, null, '33vw', null, '25vw'] }}
            slidesPerView={[1.4, null, 2.6, null, null, 3]}
            tag={tag[0]?.tag}
            text={paragraph?.[0]?.richText}
        />
    );
};

export default FeaturePromotionDataTransformerBlock;
