import PropTypes from 'prop-types';
import PromotionSlider from 'components/content-components/Promotional/PromotionSlider';

const FeaturePromotion = ({
    columnSizes = [],
    features = [],
    heading,
    headingFontKeys = ['Primary/32_110_-2', null, 'Primary/40_100_-2'],
    headingType,
    id,
    imageSettings = {},
    slidesPerView,
    tag,
    text,
}) => {
    if (features.length < 1) {
        return null;
    }

    return (
        <PromotionSlider
            $style={{ padding: ['64px 8px', null, null, null, null, '64px 24px'] }}
            columnSizes={columnSizes}
            heading={heading}
            headingFontKeys={headingFontKeys}
            headingType={headingType}
            id={id}
            imageSettings={imageSettings}
            items={features}
            slidesPerView={slidesPerView}
            tag={tag}
            text={text}
            type="feature"
        />
    );
};

FeaturePromotion.propTypes = {
    columnSizes: PropTypes.array,
    features: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    tag: PropTypes.string,
    text: PropTypes.string,
};

export default FeaturePromotion;
