import FeaturePromotionDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/FeaturePromotionDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface FeaturePromotionModuleProps {
    isGrid?: boolean;
}

const FeaturePromotionModule = ({ isGrid = false, ...rest }: FeaturePromotionModuleProps) => {
    const [css] = useStyletron();

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)' })}>
            <div className={css({ marginLeft: 'auto', marginRight: 'auto' })}>
                <FeaturePromotionDataTransformerBlock {...rest} />
            </div>
        </section>
    );
};

export default FeaturePromotionModule;
